<!--suppress JSUnresolvedVariable -->
<template>
  <div>
  <b-row>
    <b-col cols="12">
      <b-button-group v-if="!removed && this.loggedIn">
        <b-button variant="success" size="sm"
                  v-on:click="replySubmission">Reply</b-button>
        <b-button variant="warning" size="sm"
                  v-if="current_user.username === author_username || current_user.is_admin"
                  v-on:click="editSubmission">Edit</b-button>
        <b-button variant="danger" size="sm"
                  v-if="current_user.username === author_username || current_user.is_admin"
                  v-b-modal:delete-confirmation>Delete</b-button>
        <b-modal id="delete-confirmation" ok-title="Delete" ok-variant='danger'
                 title="Delete Confirmation" @ok="deleteSubmission">
          <p class="my-4">Confirm deletion of Submission #{{id}} "{{title || 'No Title'}}"</p>
        </b-modal>
      </b-button-group>
      <b-row style="padding-left: 15px" v-if="loggedIn && (current_user.is_admin || current_user.username === author_username)">
        <b-button v-if="share_key === null" style="float: right" variant="primary" size="sm" @click="enableSharing">Enable Sharing</b-button>
        <b-input-group v-else style="float: right" prepend="public url">
          <b-form-input :disabled="true" :value="share_url" style="max-width: 30%;"></b-form-input>
          <b-button variant="plain" size="md" @click="copySharableLink"><b-icon icon="files"></b-icon></b-button>
          <b-toast id="copy-share-url-toast" auto-hide-delay="2000" no-close-button>
            URL copied to clipboard!
          </b-toast>
          <b-button variant="danger" size="md" @click="disableSharing">Disable sharing</b-button>
        </b-input-group>
      </b-row>
      <b-card>
        <b-card-header>
          <b-card-title>
              <b-row>
                  <b-col cols="10">
                    {{this.title}}
                  </b-col>
                  <b-col cols="2">
                    <div style="display:flex;float:right">
                      <b-form-checkbox-group v-model="view_mode" buttons>
                          <b-form-checkbox value="raw"><b-icon-code></b-icon-code></b-form-checkbox>
                          <b-form-checkbox value="rendered"><b-icon-file-earmark></b-icon-file-earmark></b-form-checkbox>
                      </b-form-checkbox-group>
                    </div>
                  </b-col>
              </b-row>
          </b-card-title>
          <hr/>
          Author: {{this.author}} <br/>
          Submitted: {{this.datetime_submitted}} <br/>
          Last modified: {{this.datetime_last_edited}} <em>by</em> {{this.editor}}
          <div v-if="parent_id !== null && parent != null">
            Reply to: <b-link :to="'/submission/'+parent_id">#{{parent_id}} - {{parent.headers.title ? parent.headers.title : "&lt;No Title&gt;"}}</b-link>
          </div>
          <div v-if="children.length">Replies:
            <ul>
              <li v-for="reply in children" :key="reply.id">
                <b-link :to="'/submission/'+reply.id" >#{{reply.id}} - {{reply.author_username}}, <b>{{reply.headers.title}}</b></b-link>
              </li>
            </ul>
          </div>
          <div v-if="task_id !== null">
            Task: <b-link :to="'/task/'+task_id">#{{task_id}} - {{task_title}}</b-link>
          </div>
          <div>
            Tags: <Tag v-for="tag in this.tags" :key="tag.id" :id="tag.id" :description="tag.description" :color="tag.color"></Tag> <span v-if="this.tags.length===0"><em>None</em></span>
          </div>
        </b-card-header>
          <div v-if="view_mode==='raw'"><pre>{{content}}</pre></div>
          <v-md-preview v-else :text="content"></v-md-preview>
      </b-card>
    </b-col>
  </b-row>
  <b-card  no-body v-show="attachments.length > 0">
    <b-card-header>
      Attachments
      <div style="float:right">
        <a class="btn btn-success btn-sm" :href="zip_url">
          Download all
          <b-icon-download/>
        </a>
      </div>
    </b-card-header>
    <b-list-group flush>
      <b-list-group-item v-for="(attachment, index) in attachments" :key="attachment.id">
        <span>[{{index+1}}]
          <b-link :href="attachment.url" target="_blank" rel="noopener noreferrer">
                        {{attachment.original_filename}} ({{Math.ceil(10*attachment.size_bytes / 1024)/10}} kB)
          </b-link>
        </span>
        <span v-if="attachment.inline_url !== undefined" style="float: right">
          <b-button size="sm" variant="primary" v-b-toggle="'attachment-collapse-'+index">View Image <b-icon-chevron-down></b-icon-chevron-down> </b-button>
        </span>
        <b-collapse :id="'attachment-collapse-'+index">
          <b-img :src="attachment.url" style="max-width: 100%"></b-img>
        </b-collapse>
      </b-list-group-item>
    </b-list-group>
  </b-card>
  </div>
</template>

<script>
import http from "@/http-common";
import Tag from './Tag'

export default {
  name: "Submission",
  props: [
      'current_user',
      'loggedIn',
  ],
  components: {
    Tag
  },
  data: function () {
    return {
      id: null,
      title: "",
      author: "",
      author_username: "",
      datetime_submitted: null,
      editor: "",
      editor_username: "",
      datetime_last_edited: null,
      content: "",
      tags: [],
      sub_id: null,
      parent_id: null,
      attachments: [],
      parent: null,
      task_id: null,
      task_title: null,
      children: [],
      removed: false,
      share_key: null,
      share_url: null,
      zip_url: null,
      view_mode: "rendered",
    }
  },
  computed: {
  },
  methods: {
    loadSubmission(sub_id) {
      let loader = this.$loading.show()
      const load_resp = (resp) => {
        let sub = resp.data.submission;
        this.id = sub.id;
        this.title = sub.headers.title;
        this.author = sub.author_full_name;
        this.author_username = sub.author_username;
        this.datetime_submitted = new Date(sub.datetime_submitted*1000).toLocaleString();
        this.editor = sub.editor_full_name;
        this.editor_username = sub.editor_username;
        this.datetime_last_edited = new Date(sub.datetime_last_edited*1000).toLocaleString();
        this.content = sub.content;
        this.tags = sub.tags;
        this.sub_id = sub.id;
        this.parent_id = sub.parent_id;
        this.attachments = sub.resources;
        this.parent = resp.data.parent;
        this.task_id = sub.task_id;
        this.task_title = sub.task_title;
        this.children = resp.data.children;
        this.removed = sub.removed;
        this.share_key = sub.share_key;
        this.share_url = sub.share_url;
        this.zip_url = sub.zip_url;
        loader.hide();
      };
      if (this.share_key) {
        http.get(`submission/${sub_id}/shared`,
            {params: {share_key: this.share_key}})
        .then(load_resp)
        .catch((err) => {
          http.std_error_handler(this, err);
        })
        .finally(() => {
          loader.hide();
        });

      } else {
        http.get('submission/'+sub_id)
        .then(load_resp)
        .catch((err) => {
          http.std_error_handler(this, err);
        })
        .finally(() => {
            loader.hide();
        });
      }
    },
    deleteSubmission() {
      http.delete('submission/'+this.$route.params.id)
          .then(() => {
            this.$router.push("/");
          })
    },
    editSubmission() {
      this.$router.push(`/edit/${this.id}`);
    },
    replySubmission() {
      this.$router.push(`/reply/${this.id}`);
    },
    enableSharing() {
      let loader = this.$loading.show()
      http.put(`submission/${this.id}/set_public`,{},{params:{public: true}})
      .then((resp) => {
        this.share_key = resp.data.share_key;
        this.share_url = resp.data.share_url;
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      });
    },
    disableSharing() {
      let loader = this.$loading.show()
      http.put(`submission/${this.id}/set_public`,{},{params:{public: false}})
          .then((resp) => {
            this.share_key = resp.data.share_key;
            this.share_url = resp.data.share_url;
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            loader.hide();
          });
      
    },
    copySharableLink() {
      navigator.clipboard.writeText(this.share_url);
      this.$bvToast.show("copy-share-url-toast");
    },
    // zipUrl() {
    //   let url = process.env.VUE_APP_API_URL + '/resource/zip?resource_ids=';
    //   this.attachments.forEach((a) => {
    //     url += encodeURIComponent(a.id) + ','
    //   });
    //   url = url.substr(0, url.length-1);
    //   return url;
    // },
  },
  created() {
    if (this.$route.name === "view-shared-submission") {
      this.share_key = this.$route.params.share_key
    }
    this.loadSubmission(this.$route.params.id);
  },
  beforeMount() {
    if (!this.loggedIn && this.$route.name === "view-submission") {
      this.$router.push('/login');
    }
  },
}
</script>

<style>

.inline-image {
  width: 100%;
  max-width: 600px;
}

.github-markdown-body {
  padding: 5px;
}
</style>